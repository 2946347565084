.technician-assignments {
    display: flex;
    justify-content: left;
    align-items: flex-start;
}

.technician-assignments table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    vertical-align: top;
}

th {
    background-color: lightgrey;
    text-align: left;
}

.assignment-block {
    border: 1px solid #ccc;
    margin: 4px 0;
    padding: 4px;
    background-color: lightblue;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    column-count: 2;
    column-gap: 0;
}

ul {
    margin: 1px;
}
