body {
  font-size: 0.8em;
}

label {
  margin-right: 10px;
}

input[type="text"],
input[type="number"],
input[type="date"] {
  margin-right: 10px;
  padding: 0px;
}

input[type="checkbox"] {
  margin-right: 10px;
}

button {
  margin: 5px;
  padding: 2px 10px;
}

button:hover {
  cursor: pointer;
  background-color: #ddd;
}

div {
  margin-bottom: 10px;
}

.configuration {
  border: thin solid black;
  background-color: lightgrey;
  width: fit-content;
  margin: 3px;
}

.configuration th,
.configuration td {
  border: 1px solid white;
  padding: 1px;
  vertical-align: top;
}

.loadData h2 {
  font-size: 1em;
  display: inline;
}

.loadData h2::after {
  content: " > ";
}

.smartAssign {
  background-color: lightblue;
  color: black;
}

.smartAssign:hover {
  opacity: 0.8;
}

.results {
  display: flex;
}

.score, .config {
  flex: 0;
  margin: 0 10px;
}

.results h1 {
  font-size: 1em;
}

.results td {
  font-size: 0.8em;
  padding: 2px;
  background-color: lightgray;
}

.technician,
.workOrder {
  border: thin solid black;
  background-color: lightgrey;
  width: fit-content;
}

.workOrder {
  border: thin solid black;
  background-color: lightgrey;
  width: fit-content;
}

.technician th,
.technician td,
.technician button,
.workOrder th,
.workOrder td,
.workOrder button {
  border: 1px solid white;
  padding: 1px;
  vertical-align: top;
}

/* Loading spinner */
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
